import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@/components/Layout'
import Message from '@/components/Message'
import Card from '@/components/Card'
import ModalCard from '@/components/ModalCard'

// Example, see https://github.com/scttcper/gatsby-casper/blob/master/src/templates/post.tsx

interface PageTemplateProps {
  data: {
    group: {
      edges: {
        node: []
      }
    }

    groupParent: {
      edges: {
        node: [
          {
            id: string
            html?: string
            fields: {
              slug?: string
            }
            frontmatter: {
              title: string
              subTitle?: string
              tagline?: string
              templateKey?: string
              color?: string
              sequence?: string
              group?: string
            }
          }
        ]
      }
    }
  }
}

const CardPage = ({ data }: PageTemplateProps) => {
  const { edges: group } = data.group || []
  const { edges: groupParent } = data.groupParent || []

  return (
    <Layout
      pageTitle={groupParent[0].node.frontmatter?.title}
      heroTitle={groupParent[0].node.frontmatter?.title}
      heroSubTitle={groupParent[0].node.frontmatter?.subTitle}
      heroTagLine={groupParent[0].node.frontmatter?.tagline}
      heroImage={groupParent[0].node.frontmatter?.hero}
    >
      {groupParent[0].node.html && (
        <Message content={groupParent[0].node.html} />
      )}

      {group && (
        <section className="section pt-5 pt-md-0">
          <div className="container">
            <div className="row row-cols-1 row-cols-md-2">
              {groupParent[0].node.frontmatter.isModal ? (
                <ModalCard data={group} />
              ) : (
                <Card cardContent={group} />
              )}
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default CardPage

/*
 * We use ne:true and eq:true for the isGroupParent filter
 * since a group parent must have a value, while a child does not.
 *
 * isModal is set on the group parent level. When true, it will show
 * a modal iso link to a page
 */
export const cardPageQuery = graphql`
  query cardPage($group: String!) {
    group: allMarkdownRemark(
      sort: { fields: [frontmatter___sequence] }
      filter: {
        frontmatter: { group: { eq: $group }, isGroupParent: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            subTitle
            tagline
            templateKey
            color
            sequence
            group
            hero {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 94) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
              publicURL
              extension
            }
            mainImage {
              childImageSharp {
                fluid(maxWidth: 140, quality: 94) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
              publicURL
              extension
            }
            image2 {
              childImageSharp {
                fluid(maxWidth: 200, quality: 94) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
              publicURL
              extension
            }
          }
        }
      }
    }
    groupParent: allMarkdownRemark(
      sort: { fields: [frontmatter___sequence] }
      filter: {
        frontmatter: { group: { eq: $group }, isGroupParent: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            subTitle
            tagline
            templateKey
            color
            sequence
            group
            isModal
          }
        }
      }
    }
  }
`
