import React from 'react'
import { Link } from 'gatsby'

import ConditionalWrapper from '@/components/ConditionalWrapper'
import Image from '@/components/Image'

/**
 * Card component
 *
 * CardLayout is where the body is created.
 * CardWrapper translates nodes (coming from markdown files for example) to CardLayout
 *
 * Logic in Card:
 * + If it is a manual card (= not from a markdown file) => go to CardLayout
 * + If it is not an array (e.g being looped over in another component) => go to CardWrapper,
 *   which calls CardLayout
 * + If it is an array => go to CardLoop which loops over the array and calls CardWrapper,
 *   which in turn calls CardLayout
 */

interface CardNodeInterface {
  cardContent: any
  title?: never
  link?: never
}

interface CardManualInterface {
  cardContent?: never
  title: string
  link: string
}

type CardProps = CardNodeInterface | CardManualInterface

export const Card = ({ cardContent, title, link }: CardProps) => {
  // if title is given, this is a manual card like in programmas > index.jsx
  if (title) {
    return <CardManual title={title} link={link} />
  }

  // We don't know if we receive an array of objects or only an object, so handle both cases
  if (Array.isArray(cardContent)) {
    return <CardLoop cardContent={cardContent} />
  } else {
    return <CardWrapper cardContent={cardContent} />
  }
}

export const CardLoop = ({ cardContent }: any) => {
  return cardContent.map((e) => <CardWrapper cardContent={e} key={e.node.id} />)
}

export const CardManual = ({ title, link }: any) => {
  return (
    <ConditionalWrapper
      condition={link ? true : false}
      wrapper={(children) => (
        <Link to={link} className="col mb-5 anchor__simple">
          {children}
        </Link>
      )}
    >
      <CardLayout title={title} />
    </ConditionalWrapper>
  )
}

export const CardLayout = ({ mainImage, link, title, tagline, color }: any) => {
  return (
    <div className="yta--card pt-5 pb-5">
      <div className="row no-gutters align-items-center yta--card--inner">
        <div className="col-md-4">
          {mainImage ? <Image imageInfo={mainImage} /> : null}
        </div>

        <div className="col-md-8 yta--card--body">
          <h3
            className="yta--card--heading is-4"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          ></h3>
          {tagline && (
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: tagline,
                }}
              ></span>
            </p>
          )}
        </div>
      </div>

      <div className={`yta--card__active-bg ${color || 'default'}`}></div>
    </div>
  )
}

export const CardWrapper = ({ cardContent }: any) => {
  return (
    <ConditionalWrapper
      key={cardContent.node.id}
      condition={cardContent.node?.fields?.slug ? true : false}
      wrapper={(children) => (
        <Link
          to={cardContent.node.fields.slug}
          className="col mb-5 anchor__simple"
        >
          {children}
        </Link>
      )}
    >
      <CardLayout
        mainImage={cardContent?.node?.frontmatter?.mainImage}
        title={cardContent?.node?.frontmatter?.title}
        tagline={cardContent?.node?.frontmatter?.tagline}
        color={cardContent?.node?.frontmatter?.color}
      />
    </ConditionalWrapper>
  )
}

export default Card
