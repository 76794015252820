import React from 'react'
import Modal from 'react-modal'
import Image from '@/components/Image'
import Card from '@/components/Card'

/**
 * @todo: we should check if we receive a array of objects or not,
 * like with the Card component
 */

interface ModalStateProps {
  showModal: boolean
  modalItemDetail: {
    name: string
    subTitle: string
    body: string
    modalItemImage: any
  }
}

export default class ModalCard extends React.Component<any, ModalStateProps> {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      modalItemDetail: {
        name: '',
        subTitle: '',
        body: '',
        modalItemImage: undefined,
      },
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleOpenModal = (data) => {
    this.setState({
      showModal: true,
      modalItemDetail: {
        name: data.modalItem.node.frontmatter.title,
        subTitle: data.modalItem.node.frontmatter.subTitle,
        body: data.modalItem.node.html,
        modalItemImage: data.modalItem.node.frontmatter.image2,
      },
    })
  }

  handleCloseModal() {
    this.setState({ showModal: false })
  }

  render() {
    const modalItems = this.props.data

    if (!modalItems) {
      return null
    }

    return (
      <>
        {modalItems.map((modalItem) => (
          <button
            key={modalItem.node.id}
            className="btn--simple col p-3 btn--card"
            onClick={() => this.handleOpenModal({ modalItem })}
          >
            <Card cardContent={modalItem} />
          </button>
        ))}
        <Modal
          isOpen={this.state.showModal}
          contentLabel="Details"
          onRequestClose={this.handleCloseModal}
          ariaHideApp={false}
          className="modal modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
          overlayClassName="modal-backdrop"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.state.modalItemDetail.name}</h5>

              <button
                className="navbar-toggler ml-2 collapsed btn--simple"
                type="button"
                aria-label="Close"
                onClick={this.handleCloseModal}
              >
                <div
                  data-target="navbar_global"
                  className="hamburger-icon is-active"
                >
                  <span className="line line-1"></span>
                  <span className="line line-2"></span>
                  <span className="line line-3"></span>
                </div>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    {this.state.modalItemDetail.modalItemImage ? (
                      <Image
                        imageInfo={this.state.modalItemDetail.modalItemImage}
                      />
                    ) : null}
                  </div>
                  <div
                    className="col-md-8"
                    dangerouslySetInnerHTML={{
                      __html: this.state.modalItemDetail.body,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}
