import React, { useState } from 'react'

//https://stackoverflow.com/a/61281828
interface MessageBasics {
  type?: string
  collapse?: boolean
}

interface MessageWithContent extends MessageBasics {
  type?: string
  content: string
  children?: never
}

interface MessageWithChildren extends MessageBasics {
  type?: string
  content?: never
  children: React.ReactNode
}

type MessageProps = MessageWithContent | MessageWithChildren

const Message = ({ children, content, type, collapse }: MessageProps) => {
  const [collapseOpen, setCollapseOpen] = useState(false)

  const toggle = () => {
    setCollapseOpen(!collapseOpen)
  }

  const isCollapse = collapse || false
  const messageType = type

  return (
    <div className="container pt-1 ">
      <div className={`message mt-1 mb-5 ${messageType}`}>
        <div className={`container pt-5 ${isCollapse ? '' : ' pb-5'}`}>
          <div
            className={`row justify-content-md-center ${messageType}
                        ${isCollapse ? ' collapse' : ''}
                        ${collapseOpen ? ' is-active' : ''}
                      `}
          >
            <div className="col-md-10">
              {children && children}

              {content && (
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              )}
            </div>
          </div>
        </div>

        {collapse && (
          <button
            className="container-fluid test btn--simple btn--accordion p-1"
            type="button"
            onClick={toggle}
          >
            <div className="link">
              {collapseOpen
                ? 'Toon minder'
                : 'Toon meer'}
            </div>
          </button>
        )}
      </div>
    </div>
  )
}

export default Message
